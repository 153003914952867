<!--运行维护-运维单-->
<template>
  <section>
    <!-- 工具条 -->
    <el-row>
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
        <el-form :inline="true" :model="filter" size="mini">
          <el-form-item>
            <el-form-item>
              <el-cascader
                placeholder="所属单位"
                @change="getData"
                v-model="filter.Owner"
                :options="customerTree"
                :props="customerProps"
                clearable
                filterable/>
            </el-form-item>
          </el-form-item>
          <el-form-item>
            <el-select
              @change="getData"
              v-model="filter.Typ"
              filterable
              clearable
              placeholder='维护类型'>
              <el-option
                v-for="item in maintenanceTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              @change="getData"
              v-model="filter.MaintainerId"
              filterable
              clearable
              placeholder='运维人员'>
              <el-option
                v-for="item in MaintainerList"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              @change="getData"
              v-model="filter.Status"
              filterable
              clearable
              placeholder='操作状态'>
              <el-option :value="1" label="已完成"/>
              <el-option :value="2" label="未完成"/>
              <el-option :value="3" label="待处理"/>
            </el-select>
          </el-form-item>
          <el-form-item label="派单日期">
            <el-date-picker
              type="daterange"
              v-model="filter.CreateAt"
              @change="getData"
              format="yyyy-MM-dd"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="派单开始日期"
              end-placeholder="派单结束日期">
            </el-date-picker>
          </el-form-item>


          <el-form-item>
            <el-button
              type="primary"
              size="mini"
              @click="handleAdd"
            >
              新增
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-download"
              @click="openExportSizeInput"
              :loading="exportLoading"
            >
              导出
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-row>
      <el-col :span="24">
        <el-table
          :data="datalist.content"
          size="mini"
          border
          highlight-current-row
          v-loading="loading"
          @selection-change="selsChange"
          :max-height="clientHeight"
          style="width: 100%"
        >
          <el-table-column type="selection"/>
          <el-table-column type="index" label="#" align="center" width="55"/>
          <el-table-column
            prop="Id"
            label="处理单号"
            show-overflow-tooltip
            width="160"
            header-align="center"
            align="center"
          />
          <el-table-column
            prop="LocaleName"
            label="监测点"
            show-overflow-tooltip
            min-width="200"
            header-align="center"
          />
          <el-table-column
            prop="Addr"
            label="监测点地址"
            show-overflow-tooltip
            min-width="190"
            header-align="center"
          />
          <el-table-column
            prop="MN"
            label="设备编号"
            show-overflow-tooltip
            align="center"
            min-width="200"
            header-align="center"
          />
          <el-table-column
            prop="Contact"
            label="联系人"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="Mobile"
            label="联系电话"
            width="120"
            header-align="center"
          />
          <el-table-column
            prop="Typ"
            :formatter="maintenanceTypeFormatter"
            label="维护类型"
            align="center"
            width="80"
            header-align="center"
          />
          <el-table-column
            prop="CreateAt"
            :formatter="dateFormatter"
            label="派单日期"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="MaintainerName"
            label="运维人员"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="MaintainAt"
            :formatter="dateFormatter"
            label="处理日期"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="Handler"
            label="处理人员"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="Status"
            label="操作状态"
            align="center"
            width="80"
            header-align="center"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.Status === 1">已完成</div>
              <div v-else-if="scope.row.Status === 2">未完成</div>
              <div v-else-if="scope.row.Status === 3">待处理</div>
              <div v-else>未知状态</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="ExamStatus"
            label="审核状态"
            align="center"
            width="80"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-tag type="warning" v-if="scope.row.ExamStatus === 0">待审核</el-tag>
              <el-tag type="success" v-else-if="scope.row.ExamStatus === 1">通过</el-tag>
              <el-tag type="danger" v-else-if="scope.row.ExamStatus === 2">拒绝</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            min-width="120"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" size="mini"
                         :disabled="scope.row.Status === 1 || (role !== 'sa' && role !== 'Maintainer')"
                         @click="handleEdit(scope.$index, scope.row)">编辑
              </el-button>
              <el-button type="text" size="mini"
                         :disabled="scope.row.Status === 1 || (role !== 'sa' && role !== 'Maintainer' && role !== 'Charger')"
                         @click="handleDeal(scope.$index, scope.row)">处理
              </el-button>
              <el-button type="text" size="mini" @click="handleLook(scope.$index, scope.row)">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column
            label="审核"
            align="center"
            fixed="right"
            min-width="120"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-button type="primary" size="mini"
                         :disabled="scope.row.ExamStatus !== 0 || (role !== 'sa' && role !== 'Maintainer' && role !== 'Charger')"
                         @click="handleVerify(scope.row)">审核
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 工具条 -->
    <div class="toolbar" style="text-align:center">
      <el-button
        type="danger"
        size="mini"
        @click="bulkRemove"
        :disabled="sels.length === 0"
      >批量删除
      </el-button
      >
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      ></el-pagination>
    </div>

    <!--编辑界面-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      @close="handlerClose"
      width="700px"
    >
      <el-form
        :model="formData"
        :rules="formRule"
        ref="form"
        size="mini"
        label-width="120px"
        label-position="right"
        :disabled="isView"
      >
        <el-row>
          <el-col>
            <el-form-item prop="Owner" label="所属单位">
              <el-cascader
                style="width: 100%"
                placeholder="所属单位"
                v-model="formData.Owner"
                :disabled="isDeal"
                :options="customerTree"
                :props="customerProps"
                @change="handleOwnerChange"
                clearable
                filterable/>
            </el-form-item>
            <el-form-item prop="LocaleId" label="监测点">
              <el-select
                style="width: 100%"
                @change="localeChange"
                v-model="formData.LocaleName"
                filterable
                value-key="Id"
                clearable
                :disabled="!isEdit || (!isAdd && formData.Typ === 2)"
                :placeholder="isEdit ? '请选择监测点' : '监测点名称'"
              >
                <el-option
                  v-for="item in localeList"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="Addr" label="监测点地址">
          <el-input
            v-model="formData.Addr"
            disabled
            placeholder="无"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="Contact" label="联系人">
          <el-input
            v-model="formData.Contact"
            disabled
            placeholder="无"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="Mobile" label="联系电话">
          <el-input
            v-model="formData.Mobile"
            disabled
            placeholder="无"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="DeviceId" label="维护设备">
          <el-select
            @change="hasChange"
            style="width: 100%"
            v-model="formData.DeviceId"
            filterable
            clearable
            :disabled="!isEdit || (!isAdd && formData.Typ === 2)"
            placeholder="选择维护设备"
          >
            <el-option
              v-for="item in devices"
              :key="item.Id"
              :label="item.MN"
              :value="item.Id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-form-item prop="Typ" label="维护类型">
            <el-select
              @change="hasChange"
              style="width: 100%"
              v-model.trim="formData.Typ"
              clearable
              :disabled="!isEdit || (!isAdd && formData.Typ === 2)"
              placeholder="选择维护类型"
            >
              <el-option
                v-for="item in maintenanceTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="MN" v-if="formData.Typ === 9" label="更换设备">
            <el-input
              placeholder="请输入新设备编号"
              @change="getData"
              v-model="formData.MN"
              clearable/>

          </el-form-item>
          <el-form-item prop="NewOwner" v-if="formData.Typ === 2" label="新所属单位">
            <el-cascader
              style="width: 100%"
              placeholder="请选择新所属单位"
              v-model="formData.NewOwner"
              @change="handleOwnerChange"
              :options="customerTree"
              :props="customerProps"
              clearable
              filterable/>

          </el-form-item>
          <el-form-item prop="NewLocaleId" v-if="formData.Typ === 2" label="新监测点">
            <el-select
              style="width: 100%"
              @change="newLocaleChange"
              v-model="formData.NewLocaleName"
              filterable
              value-key="Id"
              clearable
              :disabled="!isEdit || (!isAdd && formData.Typ === 2)"
              placeholder="新监测点名称"
            >
              <el-option
                v-for="item in localeList"
                :key="item.Id"
                :label="item.Name"
                :value="{ LocaleId: item.Id, LocaleName: item.Name, Addr: item.Addr }"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-form-item prop="Remark" label="派单说明">
          <el-input
            placeholder="派单说明"
            type="textarea"
            :rows="3"
            autosize
            :disabled="!isEdit"
            @change="hasChange"
            v-model.trim="formData.Remark"
          />
        </el-form-item>


        <el-form-item prop="MaintainerName" label="运维人员">
          <el-select
            @change="maintainerChange"
            v-model="formData.MaintainerName"
            clearable
            :disabled="!isEdit"
            placeholder="选择运维人员"
          >
            <el-option
              v-for="item in MaintainerList"
              :key="item.Id"
              :label="item.Name"
              :value="{ Name: item.Name, Id: item.Id }"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="Status"
          label="处理状态">
          <el-select
            @change="hasChange"
            v-model.trim="formData.Status"
            clearable
            placeholder="处理状态"
          >
            <el-option :value="1" label="已完成"/>
            <el-option :value="2" label="未完成"/>
            <el-option :value="3" label="待处理"/>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="Resolution"
          label="处理措施"
          v-if="formData.Status === 1">
          <el-input
            @change="hasChange"
            type="textarea"
            :rows="2"
            autosize
            v-model.trim="formData.Resolution"
          ></el-input>
        </el-form-item>
        <el-form-item prop="Position" label="定位" v-if="formData.Status === 1">
          <baidu-map
            class="bm-view"
            :center="mapCenter"
            :zoom="mapZoom"
            ak="770b4c07458f53896ff0abd948755e20"
            @ready="handleMapReady"
            v-if="!isView"
          >
            <bm-geolocation
              anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
              :showAddressBar="true"
              :autoLocation="true"
              @locationSuccess="getPosition"
            ></bm-geolocation>
          </baidu-map>
          <span>{{ formData.Position ? formData.Position : '暂无定位' }}</span>
        </el-form-item>
        <el-form-item v-if="formData.Status === 1" label="处理照片">
          <el-image
            class="view-img"
            v-for="(item, i) in fileList"
            :key="i"
            :src="item"
            :preview-src-list="fileList"
          >
            <div slot="error" class="image-slot">
              暂无图片
            </div>
          </el-image>
        </el-form-item>
        <el-form-item v-if="formData.Status === 1 && !isView">
          <el-upload
            ref="upload"
            accept=".jpg, .jpeg, .png"
            list-type="picture-card"
            :action="upload()"
            :on-success="handleAvatarSuccess"
            :limit="4"
            :before-upload="hasChange"
            :on-exceed="handleExceed"
            :on-remove="handleRemove"
            :on-preview="handlePreview"
          >
            <i class="el-icon-plus"/>
            <div slot="tip">上传照片（最多4张，支持jpg、jpeg、png）</div>
          </el-upload>
        </el-form-item>
        <el-form-item prop="CreateAt" label="派单日期">
          <el-date-picker
            disabled
            placeholder="派单日期"
            type="date"
            @change="hasChange"
            value-format="timestamp"
            format="yyyy-MM-dd"
            v-model="formData.CreateAt"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="isEdit || isDeal">
        <el-button size="mini" @click.native="dlg.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click.native="handleSubmit">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showImg">
      <el-image
        :src="dialogImageUrl"
        fit="fill"
      >
        <div slot="error" class="image-slot">
          暂无图片
        </div>
      </el-image>
    </el-dialog>
    <el-dialog
      title="运维单审核"
      :visible.sync="verifyVisible"
      width="30%"
      center>
      <el-form>
        <el-form-item label="审核备注：">
          <el-input
            placeholder="请输入审核备注"
            v-model="verifyRemark"/>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="handleVerifyStatus(1)">通  过</el-button>
        <el-button type="danger" @click="handleVerifyStatus(2)">不通过</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import {
  dateFormater,
  dataFormatter,
  referToMap,
  getUserInfo,
  join,
  ifNull,
  maintenanceTypeFormatter,
  maintenanceTypeOptions,
  maintenanceSubtypeOptions,
  export_json, ownerJoin, toIntArray,ownerFilter
} from '@/util/index'
import conf from '@/config'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation'
import {number} from "echarts/src/export";

export default {
  name: 'Maintenance',
  components: {
    BaiduMap,
    BmGeolocation
  },
  data() {
    return {
      conf,
      // 地图相关参数
      mapCenter: {lng: 120.306305, lat: 30.424877},
      mapZoom: 14,
      changeState: false,
      index: 0, // 记录更新index
      sels: [], // 列表选中列
      exportLoading: false,
      loading: false,
      showImg: false,
      dialogImageUrl: null,
      maintenanceTypeOptions,       //四个维修类型
      maintenanceSubtypeOptions,    //三类设备
      referMap: {},
      datalist: {},
      localeList: [],
      exceptions: [
        {Id: 'DetectorProblem', Name: '监测设备'},
        {Id: 'MonitorProblem', Name: '监控设备'},
        {Id: 'FilterProblem', Name: '净化器不正常使用'},
        {Id: 'StoppedBusiness', Name: '歇业'}
      ],
      // dialog编辑状态
      isView: false,
      isEdit: false,
      isDeal: false,
      isAdd: false,
      //图片列表
      fileList: [],
      userInfo: null,
      devices: [],
      dlg: {
        visible: false,
        type: null,
        title: ''
      },
      // 表格分页器
      filter: {
        name: null,
        field: 'name',
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      },
      //运维人员名单
      MaintainerList: [],
      formData: {
        DeviceId: '',
        FormType: 1
      },
      formRule: {
        Owner: [{required: true, message: '所属单位不可为空'}],
        LocaleId: [{required: true, message: '监测点不可为空'}],
        MaintainerName: [{required: true, message: '运维人员不可为空'}],
        Typ: [{required: true, message: '类型不可为空'}],
        DeviceId: [{required: true, message: '设备不可为空'}],
        Subtype: [{required: true, message: '设备类型不可为空'}]
      },
      role: null,
      verifyVisible: false,     //审核对话框是否可视
      verifyRemark: '',     //审核备注
      verifyId: null
    }
  },
  computed: {
    ...mapState({
      customerProps: state => Object.assign({}, state.props, {label: 'Org', checkStrictly: true})
    }),
    ...mapState(['props', 'clientHeight', 'customerTree', 'user'])
  },
  mounted() {
    this.userInfo = getUserInfo().Info
    this.role = sessionStorage.getItem('role')
    this.referMap = referToMap(maintenanceTypeOptions)
    this.getData()
  },
  methods: {
    /** 点击导出按钮操作 */
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleDownload(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    handleMapReady() {
      if (this.formData.Lng && this.formData.Lat) {
        this.mapCenter = {lng: this.formData.Lng, lat: this.formData.Lat}
      }
    },
    getPosition(pos) {
      this.formData.Lng = pos.point.lng.toString()
      this.formData.Lat = pos.point.lat.toString()
      this.formData.Position = pos.addressComponent['province'] + pos.addressComponent['city'] +
        pos.addressComponent['district'] + pos.addressComponent['street'] +
        pos.addressComponent['streetNumber']
    },
    maintainerChange(val) {
      this.formData.MaintainerName = val.Name
      this.formData.MaintainerId = val.Id
    },
    localeChange(val) {
      this.formData.LocaleId = val.Id
      this.formData.LocaleName = val.Name
      this.formData.Addr = val.Addr
      this.$set(this.formData, 'DeviceId', '')
      if (val !== '') {
        this.$get('admin/listCustomerOfLocale', {LocaleId: val.Id}).then(res => {
          this.$set(this.formData, 'Contact', res.Contact)
          this.$set(this.formData, 'Mobile', res.Mobile)
        })
        this.$post('admin/getDevicesOfLocale', {LocaleId: val.Id}).then((res) => {
          this.devices = res
        })
      } else {
        this.$set(this.formData, 'Contact', '')
        this.$set(this.formData, 'Mobile', '')
        this.devices = []
      }
    },
    newLocaleChange(val) {
      this.formData.NewLocaleId = val.LocaleId
      this.formData.NewLocaleName = val.LocaleName
    },
    hasChange() {
      this.changeState = true
    },
    // uploadAction(req) {
    //   var fileObj = req.file
    //   var formData = new FormData()
    //   formData.append('IMG', fileObj)
    //   // this.$post('uploadToOss', formData).then((res) => {
    //   this.$post('admin/maintenUpload', formData).then((res) => {
    //     this.formData.Fid.push(res.id)
    //   })
    // },
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    handleAvatarSuccess(res, file) {
      this.formData.MaintenancePicS.push(res.Data)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`照片数量限制在4张`)
    },
    // handleBeforeRemove(file) {
    //   return this.$confirm("确认删除吗？").then(() => {
    //     const reg = /res\/(.*)\.[a-zA-Z]+$/;
    //     this.$get("admin/delMaintenanceAt", { fid: file.url.match(reg)[1] });
    //   });
    // },
    handleRemove(file, fileList) {
      this.formData.MaintenancePicS.forEach((v, index) => {
        if (file.response) {
          if (v.PicUrl === file.response.data) {
            this.formData.MaintenancePicS.splice(index, 1)
          }
        } else {
          if (v.PicUrl === file.url) {
            this.formData.MaintenancePicS.splice(index, 1)
          }
        }
      })
      const i = this.formData.MaintenancePicS.indexOf(file.response.data)
      this.formData.MaintenancePicS.splice(i, 1)
    },
    userFormatter(r, c) {
      return dataFormatter(r[c.property], this.user)
    },
    dateFormatter(r, c) {
      return dateFormater(r[c.property], false, false)
    },
    maintenanceTypeFormatter,
    /** 获取表单数据 */
    getData: function () {
      this.loading = true
      var para = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        FormType: 1
      }
      para.Param = {}
      //监测点信息
      if (this.filter.LocaleId) {
        para.Param['maintenance.locale_id'] = {S: 2, V: this.filter.LocaleId.toString()}
      }
      //维护类型
      if (this.filter.Typ) {
        para.Param['maintenance.typ'] = {S: 0, V: this.filter.Typ.toString()}
      }
      // 所属单位信息
      if (this.filter.Owner && this.filter.Owner.length > 0) {
        const owner = `${ownerJoin(this.filter.Owner, this.userInfo)}`
        para.Param['maintenance.owner'] = {S: 4, V: owner}
      }
      // 派单日期筛选
      if (this.filter.CreateAt) {
        para.Param['maintenance.create_at'] = []
        para.Param['maintenance.create_at'].push(0 | this.filter.CreateAt[0] / 1000)
        para.Param['maintenance.create_at'].push((0 | this.filter.CreateAt[1] / 1000) + 86400)
        para.Param['maintenance.create_at'] = {S: 11, V: para.Param['maintenance.create_at'].toString()}
      }
      //操作状态筛选
      if (this.filter.Status || this.filter.Status === 0) {
        para.Param['maintenance.status'] = {S: 0, V: this.filter.Status.toString()}
      }
      //运维人员筛选
      if (this.filter.MaintainerId) {
        para.Param['maintainer_id'] = {S: 2, V: this.filter.MaintainerId.toString()}
      }
      this.$post('admin/listMaintenance', para).then((res) => {
        this.datalist = res
        this.loading = false
      })
      //更新运维人员数据信息
      this.$get('admin/listMaintainer').then(res => {
        this.MaintainerList = res
      })
    },
    handlePreview(file) {
      this.showImg = true
      this.dialogImageUrl = file.response.Data
    },
    handleRefresh() {
      this.filter.page = 1
      this.getData()
    },
    selsChange: function (sels) {
      this.sels = sels
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    handleAdd: function () {
      this.dlg.title = '新增'
      this.dlg.visible = true
      this.dlg.type = false
      this.isEdit = true
      this.isAdd = true
      this.fileList = []
      this.formData = {
        FormType: 1,
        Status: 3,
        MaintenancePicS: [],
        CreateAt: new Date().getTime(),
        Position: ''
      }
    },
    async getPicture(id) {
      this.fileList = []
      await this.$get('admin/listMaintenanceAt', {id})
        .then((res) => {
          res.forEach((item) => {
            this.fileList.push({url: conf.baseApi + '/' + item})
          })
        })
        .catch(() => {
        })
    },
    handleEdit(index, row) {
      this.dlg.title = '编辑'
      this.dlg.visible = true
      this.dlg.type = true
      this.isEdit = true
      this.index = index
      let userInfo = getUserInfo().Info
      this.formData = Object.assign({}, row, {
        BeginAt: null,
        MaintenancePicS: [],
      })
      this.formData.Owner = ownerFilter(this.formData.Owner, userInfo)
      this.$post('admin/getDevicesOfLocale', {LocaleId: this.formData.LocaleId}).then((res) => {
        this.devices = res
      })
    },
    handleDeal(index, row) {
      this.dlg.title = '处理'
      this.dlg.visible = true
      this.isDeal = true
      this.index = index
      let userInfo = getUserInfo().Info
      this.formData = Object.assign({}, row, {
        MaintenancePicS: [],
        MaintainAt: row.Status === 3 ? new Date().getTime() : row.MaintainAt,
      })
      this.formData.Owner = ownerFilter(this.formData.Owner, userInfo)
      this.fileList = row.MaintenancePicS
    },
    handleLook(index, row) {
      this.dlg.title = '查看'
      this.dlg.visible = true
      this.index = index
      this.isView = true
      this.formData = Object.assign({}, row, {
        MaintenancePicS: [],
      })
      let userInfo = getUserInfo().Info
      this.formData.Owner = ownerFilter(this.formData.Owner, userInfo)
      this.fileList = row.MaintenancePicS
    },
    // 批量删除
    bulkRemove: function () {
      if (this.sels.length !== 0) {
        const ids = this.sels.map((item) => item.Id).toString()
        this.$confirm('确认删除选中记录吗？', '提示', {
          type: 'warning'
        })
          .then(() => {
            this.$post('admin/bulkRemoveMaintenance', {ids: ids})
              .then((res) => {
                this.getData()
              })
              .catch(() => {
              })
          })
          .catch(() => {
          })
      }
    },
    handleDel: function (index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      })
        .then(() => {
          const para = {id: row.Id}
          this.$get('admin/delMaintenance', para)
            .then(() => {
              this.datalist.content.splice(index, 1)
            })
            .catch(() => {
            })
        })
        .catch(() => {
        })
    },
    handleSubmit: function () {
      let uri
      if (!this.changeState) {
        this.dlg.visible = false
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.dlg.title !== '新增') {
            uri = 'admin/updateMaintenance'
          } else {
            uri = 'admin/saveMaintenance'
          }
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            const para = Object.assign({}, this.formData)
            let userInfo = getUserInfo().Info
            para.Owner = ownerJoin(para.Owner, userInfo)
            if (para.NewOwner) {
              para.NewOwner = ownerJoin(para.NewOwner, userInfo)
            }
            if (this.dlg.title === '处理' || para.MaintainAt) {
              para.MaintainAt = (0 | para.MaintainAt / 1000) // 小数转换为整数
            }
            if (this.dlg.title === '处理' && para.Status === 3) { // 若处理时Status未手动更改，则将默认状态的 3 改为 2
              para.Status = 2
            }
            para.CreateAt = (0 | this.formData.CreateAt / 1000)
            // para.BeginAt = para.BeginAt / 1000
            this.$post(uri, para).then(() => {
              this.dlg.visible = false
            })
          })

        }
      })
    },
    handlerClose: function () {
      if (this.changeState) {
        this.changeState = false
        this.handleRefresh()
      }
      this.isView = false
      this.isEdit = false
      this.isDeal = false
      this.isAdd = false
      this.device = []
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.formData = {
        DeviceId: '',
        FormType: 1
      }
      this.fileList = []
    },
    filterReson(typ) {
      switch (typ) {
        case 'DetectorProblem':
          return '监测设备-'
          break
        case 'MonitorProblem':
          return '监控设备-'
          break
        case 'FilterProblem':
          return '净化器不正常使用-'
          break
        case 'StoppedBusiness':
          return '歇业-'
          break
      }
    },
    handleDownload(exportSize) {
      this.exportLoading = true
      const para = {
        StartAt: 0 | (this.filter.page - 1) * this.filter.size / 1000,
        Size: exportSize,
        FormType: 1,
        IsDownload: true,
        Param: {}
      }
      if (this.filter.LocaleId) {
        para.Param['maintenance.locale_id'] = {S: 2, V: this.filter.LocaleId.toString()}
      }
      if (this.filter.Typ) {
        para.Param['maintenance.typ'] = {S: 0, V: this.filter.Typ.toString()}
      }
      if (this.filter.Owner && this.filter.Owner.length > 0) {
        const owner = `${ownerJoin(this.filter.Owner, this.userInfo)}`
        para.Param['maintenance.owner'] = {S: 4, V: owner}
      }
      if (this.filter.CreateAt) {
        para.Param['maintenance.create_at'] = []
        para.Param['maintenance.create_at'].push(0 | this.filter.CreateAt[0] / 1000)
        para.Param['maintenance.create_at'].push((0 | this.filter.CreateAt[1] / 1000) + 86400)
        para.Param['maintenance.create_at'] = {S: 11, V: para.Param['maintenance.create_at'].toString()}
      }
      if (this.filter.Status || this.filter.Status === 0) {
        para.Param['maintenance.status'] = {S: 0, V: this.filter.Status.toString()}
      }
      if (this.filter.MaintainerId) {
        para.Param['maintainer_id'] = {S: 2, V: this.filter.MaintainerId.toString()}
      }
      this.$post('admin/listMaintenance', para).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
        this.exportLoading = false
      })
    },
    /**
     * 点击审核按钮
     * @param row
     */
    handleVerify(row) {
      this.verifyRemark = ''
      this.verifyVisible = true
      this.verifyId = row.Id
    },
    /**
     * 处理审核通过与否
     * @param status
     */
    handleVerifyStatus(status) {
      console.log(this.verifyRemark)
      if (this.verifyRemark === "") {
        this.$message({
          type: 'error',
          message: '审核备注必填！'
        })
        return
      }
      let param = new FormData()
      param.append('status', status)
      param.append('id', this.verifyId)
      param.append('remark', this.verifyRemark)
      this.$post('admin/maintenance/update', param).then(res => {
        this.$message({
          type: 'success',
          message: '审核成功'
        })
        this.verifyVisible = false
        this.getData()
      })
    },
    localeSearch(locale) {

    },
    /**
     * 根据所属单位查找监测点信息
     * @param owner
     */
    handleOwnerChange(owner) {
      let param = {
        StartAt: 0,
        Size: 20,
        Param: {
          Owner: ownerJoin(owner)
        }
      }
      this.$post('admin/listLocale', param).then(res => {
        this.localeList = res.content
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bm-view {
  width: 100%;
  height: 150px;
}

.filterword {
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: 12px;
  color: #606266;
  margin-left: 10px;
  width: 80px;
  height: 28px;
  outline: 0;
  padding: 0 10px;
}

.view-img {
  width: 140px;
  height: 140px;
  margin: 0 10px;
}
</style>
